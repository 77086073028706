import * as React from 'react'

import '../components/templates/page-template.scss'
import './contact.scss'
import Layout from '../layouts'

const ContactPage = () => (
  <Layout>
    <div className="page">
      <h1>Contact us</h1>
      <div className="page-body">
        <form
          name="contact"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="contact" />
          <p>
            <label>Your Name:</label>
            <input type="text" name="name" />
          </p>
          <p>
            <label>Your Email: </label>
            <input type="email" name="email" />
          </p>
          <p>
            <label>Message: </label>
            <textarea name="message" />
          </p>
          <p>
            <button className="button" type="submit">
              Send
            </button>
          </p>
        </form>
      </div>
    </div>
  </Layout>
)

export default ContactPage
